.project-catalog-graph {
  // height: 284px;
  width: 100%;

  .project-catalog-graph-controls {
    left: auto;
    right: 10px;
    bottom: 55px;
  }

  .catalog-graph-node-wrapper {
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0 6px;

    .catalog-grapg-node-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;
      margin-right: 4px;

      color: #ffffff; // #262626;

      svg {
        font-size: 10px;
      }

      img {
        width: 10px;
        height: 10px;
      }

      .static-text {
        font-size: 5px;
        text-transform: uppercase;
      }
    }

    .catalog-grapg-node-title {
      max-width: 204px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #ffffff; // #262626;
    }

    &.seed {
      border-color: #f2b875;
      background: transparent;

      svg {
        color: #f2b875;
      }
    }

    &.view {
      border-color: #6fd7ee;
      background: transparent;

      svg {
        color: #6fd7ee;
      }
    }

    &.table {
      border-color: #b584f4;
      background: transparent;

      svg {
        color: #b584f4;
      }
    }
  }
  .react-flow > div.react-flow__panel.react-flow__attribution.bottom.right > a {
    display: none !important;
  }
}
