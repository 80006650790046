.splitter {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &.splitter-row {
     flex-direction: row;
  }

  &.splitter-column {
     flex-direction: column;
  }
}

.splitter-handler {
  &.p-layout {
     width: 5px !important;
  }
}
.splitter-handler-top{
   height: 5px !important;
}

.splitter-lead {
  z-index: 100;
  // background: #fff;

  &.p-layout-schema {
     box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
     border-left: 0.5px solid #828282;
  }

  .splitter-content {
     position: relative;
     width: 100%;
     height: 100%;

     .splitter-collapse-button,
     .splitter-toolbar {
        z-index: 1;
        position: absolute;

        button:not(.data-map-preview-export-btn) {
           display: flex;
           align-items: center;
           justify-content: center;
           width: 21px;
           height: 21px;
           line-height: 21px;
           margin: 0;
           padding: 0;
        }
     }

     .splitter-collapse-button {
        top: 7px;
      //   width: 21px;
        height: 21px;
     }

     &.left {
        margin-left: 22px;
        width: calc(100% - 22px);

        &.p-layout {
           position: relative;
           margin-left: 0;
           width: 100%;
        }

        .splitter-collapse-button {
           left: -21px;
        }
     }

     &.right {
        .splitter-collapse-button {
           right: 0;
        }
     }

     &.top {
      //   margin-top: 22px;
      //   height: calc(100% - 22px);
        height: calc(100% - 2px);

        .splitter-collapse-button {
         //   top: -21px;
           left: 9px;
        }

        .tail-toolbar {
         //   top: -21px;
            top: 7px;
           right: 9px;
        }
     }

     &.bottom {
        height: calc(100% - 22px);

        .splitter-collapse-button {
           bottom: -21px;
        }
     }

     .splitter-content-inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
     }
  }
}

.splitter-follower {
  /* background: #fff; */
  position: relative;
  flex: 1;

  > div {
     position: absolute;
     left: 0;
     top: 0;
     right: 0;
     bottom: 0;
  }
}

.splitter-collapsed-panel {
  display: flex;
  /* width: 100%; */
  height: 100%;
  z-index: 1;

  &.left,
  &.right {
     flex-direction: column;
     width: 22px;

     &.p-layout {
        &.left {
           border-left: 0.5px solid #828282;
        }
        &.right {
          //  border-right: 0.5px solid #828282;
           border-right: 0px solid #828282;
        }
     }

     .splitter-content-panel-content {
        > * {
           margin: 10px 0;
        }
     }

     &.p-layout {
        &.right {
          //  width: 74px;
           width: 0px;
           height: 100%;

           .splitter-content-panel-content {
              height: 100%;
           }
        }

        .splitter-content-panel-content {
           .p-layout-tabs-container {
              .ant-btn {
                 width: 50px;
                 height: 50px;
              }
           }
        }
     }
  }

  &.top,
  &.bottom {
     flex-direction: row;
     height: 30px;

     &.p-layout {
        &.top {
           border-top: 0.5px solid #828282;
        }
        &.bottom {
           border-bottom: 0.5px solid #828282;
        }
     }

     .splitter-content-panel-content {
        width: 100%;
        > * {
           margin: 0 10px;
        }
     }
  }

  &.left {
     border-left: 1px solid #e2e2e2;
  }

  &.right {
     border-right: 1px solid #e2e2e2;
  }

  &.left,
  &.right {
     button {
        margin-top: 9px;
     }
  }

  &.top {
     border-top: 1px solid #e2e2e2;
  }

  &.bottom {
     border-bottom: 1px solid #e2e2e2;
  }

  &.top,
  &.bottom {
     button {
        margin-left: 9px;
     }
  }

  button {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 21px;
     height: 21px;
     line-height: 21px;
     margin: 0;
     padding: 0;
  }
}

.splitter-handler-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.top,
  &.bottom {
     .splitter-handler {
        width: 27px;
        height: 4px;
        border-top: 1px solid #34c300;
        border-bottom: 1px solid #34c300;
     }
  }

  &.left,
  &.right {
     .splitter-handler {
        height: 27px;
        width: 4px;
        border-left: 1px solid #34c300;
        border-right: 1px solid #34c300;
     }
  }
}

.loading-bars {
   pointer-events: none;
   display: inline-block;
   aspect-ratio: 1 / 1;
   width: 1.5rem;
   background-color: currentColor;
   -webkit-mask-size: 100%;
   mask-size: 100%;
   -webkit-mask-repeat: no-repeat;
   mask-repeat: no-repeat;
   -webkit-mask-position: center;
   mask-position: center;
   mask-image: url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9JzI0JyBoZWlnaHQ9JzI0JyB2aWV3Qm94PScwIDAgMjQgMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHN0eWxlPi5zcGlubmVyX2h6bEt7YW5pbWF0aW9uOnNwaW5uZXJfdmM0SCAuOHMgbGluZWFyIGluZmluaXRlO2FuaW1hdGlvbi1kZWxheTotLjhzfS5zcGlubmVyX2tvR1R7YW5pbWF0aW9uLWRlbGF5Oi0uNjVzfS5zcGlubmVyX1lGMXV7YW5pbWF0aW9uLWRlbGF5Oi0uNXN9QGtleWZyYW1lcyBzcGlubmVyX3ZjNEh7MCV7eToxcHg7aGVpZ2h0OjIycHh9OTMuNzUle3k6NXB4O2hlaWdodDoxNHB4O29wYWNpdHk6LjJ9fTwvc3R5bGU+PHJlY3QgY2xhc3M9J3NwaW5uZXJfaHpsSycgeD0nMScgeT0nMScgd2lkdGg9JzYnIGhlaWdodD0nMjInLz48cmVjdCBjbGFzcz0nc3Bpbm5lcl9oemxLIHNwaW5uZXJfa29HVCcgeD0nOScgeT0nMScgd2lkdGg9JzYnIGhlaWdodD0nMjInLz48cmVjdCBjbGFzcz0nc3Bpbm5lcl9oemxLIHNwaW5uZXJfWUYxdScgeD0nMTcnIHk9JzEnIHdpZHRoPSc2JyBoZWlnaHQ9JzIyJy8+PC9zdmc+);
   -webkit-mask-image: url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9JzI0JyBoZWlnaHQ9JzI0JyB2aWV3Qm94PScwIDAgMjQgMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHN0eWxlPi5zcGlubmVyX2h6bEt7YW5pbWF0aW9uOnNwaW5uZXJfdmM0SCAuOHMgbGluZWFyIGluZmluaXRlO2FuaW1hdGlvbi1kZWxheTotLjhzfS5zcGlubmVyX2tvR1R7YW5pbWF0aW9uLWRlbGF5Oi0uNjVzfS5zcGlubmVyX1lGMXV7YW5pbWF0aW9uLWRlbGF5Oi0uNXN9QGtleWZyYW1lcyBzcGlubmVyX3ZjNEh7MCV7eToxcHg7aGVpZ2h0OjIycHh9OTMuNzUle3k6NXB4O2hlaWdodDoxNHB4O29wYWNpdHk6LjJ9fTwvc3R5bGU+PHJlY3QgY2xhc3M9J3NwaW5uZXJfaHpsSycgeD0nMScgeT0nMScgd2lkdGg9JzYnIGhlaWdodD0nMjInLz48cmVjdCBjbGFzcz0nc3Bpbm5lcl9oemxLIHNwaW5uZXJfa29HVCcgeD0nOScgeT0nMScgd2lkdGg9JzYnIGhlaWdodD0nMjInLz48cmVjdCBjbGFzcz0nc3Bpbm5lcl9oemxLIHNwaW5uZXJfWUYxdScgeD0nMTcnIHk9JzEnIHdpZHRoPSc2JyBoZWlnaHQ9JzIyJy8+PC9zdmc+);
}


.splitter-content-inner .footer-tab .ant-tabs .ant-tabs-tab{
   padding: 6px 0 !important;
}