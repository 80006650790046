@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Albert+Sans');

@layer base {
   :root {
      --color-fill: 246, 246, 250; /*light grey or slate-500 */
      --color-primary: 16, 85, 185; /* green color */
      --color-secondary: 255, 200, 20; /* yellow-400 */
      --color-primary-hover: 255, 200, 20; /* yellow-400 */
      --color-primary-light: 165, 184, 181; /*  green light color */
      --color-background-dark: 20, 20, 20; /*  green light color */
      --color-side-bar-bg: 2, 2, 2;
      --color-default-bg: 23, 25, 26;
      --color-secondary-bg: 43, 43, 44;
      --color-secondary-bg-1: 30, 32, 33;
      --color-secondary-bg-2: 53, 56, 58;
      --color-text-main: 255, 255, 255;
      --color-border: 130, 130, 130;
   }
   .theme-dark {
      --color-fill: 246, 246, 250; /*light grey or slate-500 */
      --color-primary: 58, 117, 181; /* green color */
      --color-secondary: 255, 200, 20; /* yellow-400 */
      --color-primary-hover: 255, 200, 20; /* yellow-400 */
      --color-primary-light: 165, 184, 181; /*  green light color */
      --color-background-dark: 20, 20, 20; /*  green light color */
      --color-side-bar-bg: 2, 2, 2;
      --color-default-bg: 23, 25, 26;
      --color-secondary-bg: 43, 43, 44;
      --color-secondary-bg-1: 30, 32, 33;
      --color-secondary-bg-2: 53, 56, 58;
      --color-text-main: 255, 255, 255;
      --color-selected: 229, 231, 235;
      --color-un-selected: 107, 114, 128;
   }
   .theme-light {
      --color-fill: 246, 246, 250; /*light grey or slate-500 */
      --color-primary: 58, 117, 181; /* green color */
      --color-secondary: 255, 200, 20; /* yellow-400 */
      --color-primary-hover: 255, 200, 20; /* yellow-400 */
      --color-primary-light: 165, 184, 181; /*  green light color */
      --color-background-dark: 20, 20, 20; /*  green light color */
      --color-side-bar-bg: 240, 240, 240;
      --color-default-bg: 255, 255, 255;
      --color-secondary-bg: 66, 109, 172;
      --color-secondary-bg-1: 30, 32, 33;
      --color-secondary-bg-2: 53, 56, 58;
      --color-text-main: 0, 0, 0;
      --color-selected: 45, 45, 45;
      --color-un-selected: 107, 114, 128;
   }
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;


}

.ant-tree .ant-tree-treenode {
  display: flex;
  padding: 0 0 4px 0;
  outline: none;
  justify-content: start;
  align-items: center;
}
.ant-tree-node-content-wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* overflow: hidden; */
}
.ant-tree-switcher{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tree-title{
  width: 100%;
}

.type-select .ant-select-selection-item{
   color: rgba(var(--color-text-main));
}
code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.model-action .ant-btn-compact-first-item{
   border-right-color: #3c86d4;
    border-right-width: 2px;
    height: 30px !important;
    border-radius: 4px;
}
.model-action .ant-btn-compact-last-item{
   height: 30px !important;
   border-radius: 4px;
}